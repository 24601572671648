const IMPRESSION = {
  e: 'im',
};

const ACTIVITY = {
  e: 'ac',
};

export const PAGE_SPEC = {
  LANDING_PAGE: {
    pgrp: 'main:donate:wps::landing',
    page: 'main:donate:wps::landing:web::',
    tmpl: 'main:donate:wps::landing',
  },
  GUEST_FORM: {
    pgrp: 'main:donate:wps:guest:guestform',
    page: 'main:donate:wps:guest:guestform:web::',
    tmpl: 'main:donate:wps:guest:guestform:web::',
  },
  GUEST_SUCCESS_PAGE: {
    pgrp: 'main:donate:wps:guest:success',
    page: 'main:donate:wps:guest:success:web::',
    tmpl: 'main:donate:wps:guest:success:web::',
  },
  GUEST_SIGNUP_SUCCESS_PAGE: {
    pgrp: 'main:donate:wps:guest:upgrade',
    page: 'main:donate:wps:guest:upgrade:web::',
    tmpl: 'main:donate:wps:guest:upgrade:web::',
  },
  GUEST_PENDING_PAGE: {
    pgrp: 'main:donate:wps:guest:pending',
    page: 'main:donate:wps:guest:pending:web::',
    tmpl: 'main:donate:wps:guest:pending:web::',
  },
  GUEST_FAILURE_PAGE: {
    url: '/donate/error/payment',
    pgrp: 'main:donate:wps:guest:failure',
    page: 'main:donate:wps:guest:failure:web::',
    tmpl: 'main:donate:wps:guest:failure:web::',
  },
  ONBOARDING_GUEST_FORM: {
    comp: 'donatenodeweb', // Overriding the comp to tell that this event is fired by DNW
    pgrp: 'main:donate:wps:signup:signupform',
    page: 'main:donate:wps:signup:signupform:web::',
    tmpl: 'main:donate:wps:signup:signupform:web::',
  },
  ONBOARDING_GUEST_SUCCESS_PAGE: {
    pgrp: 'main:donate:wps:signup:success',
    page: 'main:donate:wps:signup:success:web::',
    tmpl: 'main:donate:wps:signup:success:web::',
  },
  ONBOARDING_GUEST_PENDING_PAGE: {
    pgrp: 'main:donate:wps:signup:pending',
    page: 'main:donate:wps:signup:pending:web::',
    tmpl: 'main:donate:wps:signup:pending:web::',
  },
  MEMBER_SUCCESS_PAGE: {
    url: '/donate/member/confirmation',
    pgrp: 'main:donate:wps:member:success',
    page: 'main:donate:wps:member:success:web::',
    tmpl: 'main:donate:wps:member:success:web::',
  },
  MEMBER_PENDING_PAGE: {
    url: '/donate/member/pending',
    pgrp: 'main:donate:wps:member:pending',
    page: 'main:donate:wps:member:pending:web::',
    tmpl: 'main:donate:wps:member:pending:web::',
  },
  MEMBER_FAILURE_PAGE: {
    url: '/donate/member/error',
    pgrp: 'main:donate:wps:member:failure',
    page: 'main:donate:wps:member:failure:web::',
    tmpl: 'main:donate:wps:member:failure:web::',
  },
  RECURRING_SUCCESS_PAGE: {
    url: '/donate/member/confirmation-rec',
    pgrp: 'main:donate:wps:member:success',
    page: 'main:donate:wps:member:success:web::',
    tmpl: 'main:donate:wps:member:success:web::',
  },
  MEMBER_REVIEW_PAGE: {
    url: '/donate/member',
    pgrp: 'main:donate:wps:member:review',
    page: 'main:donate:wps:member:review:web::',
    tmpl: 'main:donate:wps:member:review:web::',
  },
  CROSS_BORDER_PAGE: {
    url: '/donate/guest/crossborder',
    pgrp: 'main:donate:wps:guest:crossborder',
    page: 'main:donate:wps:guest:crossborder:web::',
    tmpl: 'main:donate:wps:guest:crossborder:web::',
  },
  THREE_DS_IFRAME: {
    pgrp: 'main:donate:wps:guest:3DS-challenge',
    page: 'main:donate:wps:guest:3DS-challenge:::',
    tmpl: 'main:donate:wps:guest:3DS-challenge:::',
  },
  THREE_DS_IFRAME_ONBRD: {
    pgrp: 'main:onbrd:signup:donate:3DS-challenge',
    page: 'main:onbrd:signup:donate:3DS-challenge:::',
    tmpl: 'main:onbrd:signup:donate:3DS-challenge:::',
  },
  UNILATERAL_ERROR_PAGE: {
    pgrp: 'main:donate:wps:guest:unilateral',
    page: 'main:donate:wps:guest:unilateral:web::',
    tmpl: 'main:donate:wps:guest:unilateral:web::',
  },
  CAPTURE_ORDER_PAGE: {
    pgrp: 'main:donate:wps:{flow}:captureOrder',
    page: 'main:donate:wps:{flow}:captureOrder:web::',
    tmpl: 'main:donate:wps:{flow}:captureOrder:web::',
  },
  WEBSCR_ERROR_PAGE: {
    pgrp: 'main:donate:wps::error',
    page: 'main:donate:wps::error:web::',
    tmpl: 'main:donate:wps::error:web::',
  },
  GENERIC_ERROR_PAGE: {
    pgrp: 'main:donate:wps:generic:failure',
    page: 'main:donate:wps:generic:failure:web::',
    tmpl: 'main:donate:wps:generic:failure:web::',
  },
  CRS_ERROR_PAGE: {
    pgrp: 'main:donate:wps:crs:failure',
    page: 'main:donate:wps:crs:failure:web::',
    tmpl: 'main:donate:wps:crs:failure:web::',
  },
};

export const CPL_PAGE_SPEC = {
  LANDING_PAGE: {
    pgrp: 'main:donate:wps::landing:landingPageComponent',
    page: 'main:donate:wps::landing:landingPageComponent:web::',
    action: 'landing_page_loaded',
    uicomp: 'landingPageComponent',
    uitype: 'page',
  },
  GUEST_FORM: {
    pgrp: 'main:donate:wps:guest:guestform:guestFormComponent',
    page: 'main:donate:wps:guest:guestform:guestFormComponent:web:',
    action: 'guest_form_loaded',
    uicomp: 'guestForm',
  },
  TESLA_GUEST_FORM: {
    pgrp: 'main:onbrd:signup:donate:guestFormComponent',
    page: 'main:onbrd:signup:donate:guestFormComponent:web:',
    action: 'tesla_form_loaded',
    uicomp: 'teslaForm',
  },
};

export const LINK_NAME = {
  ACCOUNT_HOME: 'myAccount',
  ANONYMOUS_CHECKBOX: 'anonymous_checkbox',
  ANONYMOUS_TOOLTIP: 'anonymousTooltip',
  BACK: 'back',
  CANCEL: 'cancel',
  CHANGE_COUNTRY: 'changeCountry',
  COVER_FEE_CHECKBOX: 'cover_fee_checkbox',
  CURRENCY_CONVERSION: 'showCurrencyConversionModal',
  DONE: 'done',
  GUEST_FORM_PAYMENT_SUBMIT_BUTTON: 'donate',
  NOT_YOU: 'not-you',
  NOTE_PAD: 'note_pad',
  ONBOARDING_FORM_PAYMENT_SUBMIT_BUTTON: 'guestPaymentBtn',
  PRESET_AMOUNT: 'presetAmount',
  PROGRAM_SELECTED: 'campaignSelected',
  RECURRING_CHECKBOX: 'recurringChkBx',
  REPORT_LINK: 'reportInappropriateContent',
  RETURN: 'return_to_merchant',
  SOCIAL_SHARE: 'social_share',
  SOCIAL_SHARE_DISMISS: 'close_social_share',
  UNIFIED_LOGIN: 'requestUnifiedLogin',
  VOUCHER: 'voucher',
  VOUCHER_PRINT: 'voucherprint',
  VOUCHER_SHARE: 'vouchershare',
  LIKE: 'like',
  UNLIKE: 'unlike',
};

export const FPTI_TAGS = {
  ACCOUNT_TYPE: 'account_type',
  ACTION: 'action',
  AMOUNT: 'amount',
  AMOUNT_EDIT_ALLOWED: 'allow_amount_edit',
  AUTH_STATE: 'authState',
  CAMPAIGN: 'campaign',
  CAMPAIGN_ID: 'campaign_id',
  CHALLENGE_END_TIME: 'challenge_end_time',
  CHALLENGE_START_TIME: 'challenge_start_time',
  CHALLENGE_STATUS: 'challenge_status', // for 3ds
  CHARITY_NAME: 'charityname',
  CHECKBOX: 'checkbox',
  COUNTRY: 'country',
  CONTINGENCY_RESOLVED: 'contingency_resolved',
  CURRENCY: 'currency',
  DONOR_IP_COUNTRY: 'acct_cntry',
  EVENT_NAME: 'event_name',
  ECCD: 'eccd', // error code
  ENABLED_FREQUENCY: 'enabled_frequency',
  ERPG: 'erpg', // error message
  ERR_CODE: 'error_code',
  ERR_DESC: 'error_desc',
  ERR_FIELD: 'erfd',
  EXPERIMENT_ID: 'xe',
  FEE_AMOUNT: 'fee_amt',
  FEE_CURRENCY: 'fee_curr',
  FEE_PAYER: 'fee_payer',
  FI_NETWORK: 'sel_fmx_tp', // payment card network
  FI_ID: 'fi_id',
  FI_OPERATION: 'fi_operation',
  FI_TYPE: 'fi_type', // payment card type
  FLOW: 'flow',
  LINK: 'link',
  MODULE: 'module',
  LINK_NAME: 'link_name',
  NONANONYMOUS: 'nonanonymous',
  NPID: 'crid', // non-profit ID
  NOTE_ALLOWED: 'is_allow_note',
  NOTE_ADDED: 'has_note',
  INTEGRATION_IDENTIFIER: 'integration_identifier',
  IS_CVV_SENT: 'is_cvv_sent',
  IS_RECURRING: 'is_recurring',
  IS_SIGNUP: 'user_opts_signup',
  IS_3DS_ELIGIBLE: 'is_3ds_eligible',
  ORDER_ID: 'order_id',
  OFFER_TYPE: 'offer_type',
  PAGE_LINK: 'pgln',
  PAGE_NAME: 'page',
  PAGE_GROUP: 'pgrp',
  PAGE_GROUP_LINK: 'pglk',
  PRODUCT: 'product',
  RESP_DURATION: 'resp_duration', // for 3ds
  SPACE_KEY: 'space_key',
  SCREEN: 'screen',
  SELECTED_PRESET_BUTTON_ID: 'opsel',
  SENDER_ID: 'encr_cust_id',
  SELECTED_FREQUENCY: 'selected_frequency',
  STATUS: 'status',
  STATUS_CODE: 'status_code',
  PROCESS_STATUS: 'process_status',
  SWITCH: 'switch',
  TOKEN: 'token',
  THREE_DS_DCC_START_TIME: 'dcc_start_time',
  THREE_DS_DCC_END_TIME: 'dcc_end_time',
  THREE_DS_LOOKUP_END_TIME: 'lookup_end_time',
  THREE_DS_LOOKUP_START_TIME: 'lookup_start_time',
  THREE_DS_REF_ID: '3ds_ref_id',
  THREE_DS_RESP_DURATION: 'resp_duration',
  THREE_DS_SPINNER_START_TIME: 'spinner_start_time',
  THREE_DS_SPINNER_END_TIME: 'spinner_end_time',
  THREE_DS_VERSION: '3ds_version',
  TREATMENT_ID: 'xt',
  TXN_ID: 'trid',
  UI_TYPE: 'ui_type', // full_page/popup/popup_blocked
  URL: 'url',
  UOB_BUNDLE_TYPE: 'onboardingBundleType',
  VERSION: 'version',
  FEATURE: 'feature',
  SOURCE: 'source',
  UPDATE_ID: 'update_id',
  DISMISS_METHOD: 'dismiss_method',
  COUNT: 'count',
  STATE: 'state',
  AMOUNT_TYPE: 'amttyp',
  ENCR_RCVR_ID: 'encr_rcvr_id',
  MERCHANT_EMAIL: 'merchant_email',
  NP_ID: 'np_id',
  RECURRING_VISUAL_EXPERIMENT: 'recurring_visual_experiment'
};

export const CLOSE_SHEET_REASONS = {
  BACKDROP_CLICK: 'backdropClick',
  CLOSE_BUTTON: 'closeButton',
  ESC: 'ESC',
  SWIPE_DOWN: 'swipeDown',
};

export const DISMISS_METHODS = {
  CLOSE: 'close',
  BACKGROUND: 'background',
};

export const LOADING_STATES = {
  POPULATED: 'populated',
  EMPTY: 'empty',
};

export const FEATURES = {
  CTF: 'CTF',
  RECURRING: 'recurring',
  PRESET: 'preset',
  ANONYMOUS: 'anonymous',
  CHARITY_NOTE: 'charityNote',
  DONATION_AMOUNT_EDITABLE: 'donationAmountEditable',
  GUEST: 'guest',
  GIFT_AID: 'giftAid',
  MAILING_ADDRESS: 'mailingAddress',
  SOCIAL_SHARE_PREFERENCE: 'socialSharePreference',
};

export const FPTI_SPEC = {
  CONFIRMATION_PAGE_SET_FAV_CHARITY_MODULE_SHOWN: {
    event_name: 'donate_donor_view_post_donation_set_fav_charity_module_shown',
    data: {
      ...IMPRESSION,
    },
  },
  CONFIRMATION_PAGE_CREATE_FUNDRAISER_MODULE_SHOWN: {
    event_name:
      'donate_donor_view_post_donation_create_fundraiser_module_shown',
    data: {
      ...IMPRESSION,
    },
  },
  CONFIRMATION_PAGE_INSIGHTS_MODULE_SHOWN: {
    event_name: 'donate_donor_view_post_donation_insights_module_shown',
    data: {
      ...IMPRESSION,
    },
  },
  CONFIRMATION_PAGE_SET_FAV_CHARITY_SUCCESS: {
    event_name: 'donate_donor_view_post_donation_set_favorite_charity_success',
    data: {
      ...IMPRESSION,
    },
  },
  DONATE_ADROIT_REDIRECTION_FOR_CIP_VERIFICATION: {
    event_name: 'donate_adroit_redirection_for_cip_verification',
    data: {
      ...ACTIVITY,
    },
  },
};

export const FPTI_VALUES = {
  SPACE_KEY_ID: 'SKBWIZ',
};
